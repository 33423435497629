import { inject, observer } from 'mobx-react';
import React, {Component, Fragment} from 'react';
import { Container, Header, Segment, Image } from 'semantic-ui-react';
import { ph_serverUnderMaint_Image } from '../utils/DefaultImage';

@observer
export default class ServerUnderMaintenanceScreen extends Component {

	render() {
		console.log("R. Server Under Maintenance Screen. ");
		return(
			<Container fluid className="serverUnderMaintenance">
				<Segment className="msg">
					<div className="msgBox">
						<Image bordered circular src={ph_serverUnderMaint_Image} />
						<div className="header">
							<h3>We'll be back soon!</h3>
						</div>
						<h2>We are getting some tune up and some love. We apologize for the inconvenience.</h2>
						<h2>Thanks for your patience!</h2>
					</div>
				</Segment>
			</Container>
		);
	}
}